import React, { useState } from "react";
import "../css/style.css";
import FadeInSection from "./FadeIn";
import { useMediaQuery } from "react-responsive";

interface PProp {
  Class?: string;
}

const faqs = [
  {
    question: "What is asset tokenization?",
    answer:
      "Asset tokenization is the process of converting real-world assets, like real estate, stocks, intellectual property, royalties, infrastructure (...and more) into digital tokens on the blockchain. These tokens represent a share of the asset that can be bought or sold, which makes raising funds, transferring assets and investing in businesses more efficient and accessible to more people.",
    link: "",
    linkText: "",
  },
  {
    question: "What is the process of asset tokenization on Trendx?",
    answer: `To tokenize your assets on Trendx you will need to go through our business onboarding process which involves some background checks to verify the legitimacy of your assets and other claims, if you meet our requirements then our dedicated blockchain/tokenization team will kickstart the process of your token creation, read more`,
    link: "https://drive.google.com/file/d/1zGS56ZFhIDmvIlrCim1ZXNkSe14YFHcx/view?pli=1",
    linkText: "about our tokenization process",
  },
  {
    question: "How does Trendx vet the businesses listed on its platform?",
    answer:
      "Trendx has a rigorous vetting process for businesses seeking to list on our platform, ensuring they meet our high standards, read more about ",
    link: "https://drive.google.com/file/d/1RQbmVBrQ-wsU9VK4zltDTjiH74eJ1TvK/view",
    linkText: "our vetting process.",
  },
  {
    question:
      "What types of businesses are eligible for asset tokenization on Trendx?",
    answer:
      "We offer tokenization across a wide range of industries, we have categorized projects that can be tokenized on Trendx by industry, and considering exit plans, ",
    link: "https://drive.google.com/file/d/1q-Gfo0A9kIfNH60Za6CW86hThe__fq84/view",
    linkText: "see the project type",
  },
  {
    question: "What benefits does asset tokenization offer?",
    answer: "Asset tokenization offers numerous benefits, including Increased liquidity: Tokenization creates a digital representation of the asset, making it easily transferable and accessible to a broader investor base. Reduced costs: Tokenization streamlines processes, reducing administrative and transaction costs. Enhanced transparency: Blockchain technology provides a transparent and tamper-proof record of ownership and transactions.",
    link: "#",
    linkText: "",
  },
  {
    question:
      "Are tokenized assets regulated?",
    answer:
      "Yes, tokenized assets are regulated. The regulatory framework varies depending on the asset type (real estate, commodities), country or region and token classification (security token or utility token). These assets are subject to various compliance requirements, including Anti-Money Laundering (AML) regulations, Know-Your-Customer (KYC) protocols, and registration with applicable regulatory bodies. ",
    link: "#",
    linkText: "",
  },
  // {
  //   question: "How do I make profit from my investments on Trendx?",
  //   answer:
  //     "At TX, we offer a unique opportunity to invest in innovative projects and earn profits through various exit strategies. Our tokenized project financing platform provides a secure and transparent way to invest in projects with high growth potential read more about ",
  //   link: "https://drive.google.com/file/d/1j-CR2hWcnp2s6nXOychlMiscFdRb8zEW/view",
  //   linkText: "our profit map",
  // },
];

const FAQ = ({ Class }: PProp) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={`faq-container ${Class}`}>
      <ul className="faq-list">
        {faqs.map((faq, index) => (
          <FadeInSection>
            <li key={index} className="faq-item">
              <button
                className="faq-question"
                onClick={() => toggleAnswer(index)}
              >
                <p>{faq.question}</p>

                {activeIndex === index ? (
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.5 16C28.5 16.3978 28.342 16.7794 28.0607 17.0607C27.7794 17.342 27.3978 17.5 27 17.5H5C4.60218 17.5 4.22064 17.342 3.93934 17.0607C3.65804 16.7794 3.5 16.3978 3.5 16C3.5 15.6022 3.65804 15.2206 3.93934 14.9393C4.22064 14.658 4.60218 14.5 5 14.5H27C27.3978 14.5 27.7794 14.658 28.0607 14.9393C28.342 15.2206 28.5 15.6022 28.5 16Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.66663 16.0003H25.3333M16 6.66699V25.3337"
                      stroke="white"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
              {activeIndex === index && (
                <div className="faq-answer">
                  {faq.answer}{" "}
                  <a href={faq.link} style={{ color: "rgba(71, 141, 206, 1)" }}>
                    {faq.linkText}
                  </a>
                </div>
              )}
            </li>
          </FadeInSection>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;
