import React from "react";
import { Link } from "react-router-dom";
import "../css/style.css";
import Logo from "../assests/images/Logo.svg";
import AppStoreBtn from "./AppStoreBtn";
import PlayStoreBtn from "./PlayStoreBtn";
import ig from "../assests/images/ig.svg";
import { useMediaQuery } from "react-responsive";

interface NavProps {
  onDefi?: any;
  onTxApp?: any;
  onTkProjects?: any;
  onFactoryProject?: any;
  onReserveCard?: any;
  faq?: any;
}

export default function Footer({
  onDefi,
  onTxApp,
  onTkProjects,
  onFactoryProject,
  onReserveCard,
  faq,
}: NavProps) {
  const isMobile = useMediaQuery({ maxWidth: 820 });

  return (
    <div style={{ width: "100%" }}>
      {isMobile ? (
        <div className="mobile-footer">
          <div className="mf-content">
            <Link className="footer-logo" to="/">
              <img src={Logo} />
            </Link>
            <div className="socials">
              <p className="s-card-head">Follow us</p>
              <div className="s-icons">
                <Link
                  to="https://www.linkedin.com/company/trendx/mycompany/"
                  target="_blank"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4256_30010)">
                      <path
                        d="M18.375 0H5.625C2.5184 0 0 2.5184 0 5.625V18.375C0 21.4816 2.5184 24 5.625 24H18.375C21.4816 24 24 21.4816 24 18.375V5.625C24 2.5184 21.4816 0 18.375 0Z"
                        fill="white"
                      />
                      <path
                        d="M18.375 0H5.625C2.5184 0 0 2.5184 0 5.625V18.375C0 21.4816 2.5184 24 5.625 24H18.375C21.4816 24 24 21.4816 24 18.375V5.625C24 2.5184 21.4816 0 18.375 0Z"
                        fill="#0A66C2"
                      />
                      <path
                        d="M17.317 20.408H20.0611C20.1605 20.408 20.2559 20.3685 20.3262 20.2982C20.3965 20.2279 20.4361 20.1325 20.4361 20.0331L20.4375 14.2354C20.4375 11.2051 19.7845 8.87588 16.2433 8.87588C14.8972 8.82581 13.6277 9.51975 12.9427 10.6785C12.9393 10.6841 12.9342 10.6885 12.9281 10.691C12.9221 10.6934 12.9154 10.6938 12.909 10.6921C12.9027 10.6904 12.8971 10.6867 12.8931 10.6815C12.8891 10.6763 12.8869 10.67 12.8869 10.6634V9.53063C12.8869 9.43117 12.8474 9.33579 12.777 9.26546C12.7067 9.19513 12.6113 9.15563 12.5119 9.15563H9.90778C9.80832 9.15563 9.71294 9.19513 9.64262 9.26546C9.57229 9.33579 9.53278 9.43117 9.53278 9.53063V20.0325C9.53278 20.132 9.57229 20.2273 9.64262 20.2977C9.71294 20.368 9.80832 20.4075 9.90778 20.4075H12.6517C12.7511 20.4075 12.8465 20.368 12.9168 20.2977C12.9871 20.2273 13.0267 20.132 13.0267 20.0325V14.8413C13.0267 13.3734 13.3051 11.9519 15.1249 11.9519C16.9188 11.9519 16.942 13.6315 16.942 14.9363V20.033C16.942 20.1324 16.9815 20.2278 17.0519 20.2981C17.1222 20.3685 17.2176 20.408 17.317 20.408ZM3.5625 5.59012C3.5625 6.70237 4.47816 7.61756 5.5905 7.61756C6.70256 7.61747 7.61766 6.70172 7.61766 5.58966C7.61747 4.47759 6.70228 3.5625 5.59012 3.5625C4.47769 3.5625 3.5625 4.47787 3.5625 5.59012ZM4.21491 20.408H6.96244C7.06189 20.408 7.15728 20.3685 7.2276 20.2981C7.29793 20.2278 7.33744 20.1324 7.33744 20.033V9.53063C7.33744 9.43117 7.29793 9.33579 7.2276 9.26546C7.15728 9.19513 7.06189 9.15563 6.96244 9.15563H4.21491C4.11545 9.15563 4.02007 9.19513 3.94974 9.26546C3.87942 9.33579 3.83991 9.43117 3.83991 9.53063V20.033C3.83991 20.1324 3.87942 20.2278 3.94974 20.2981C4.02007 20.3685 4.11545 20.408 4.21491 20.408Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4256_30010">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>

                <Link
                  to="https://www.instagram.com/trendx.space/"
                  target="_blank"
                >
                  <img src={ig} alt="" />
                </Link>

                <Link
                  to="https://twitter.com/trendxapp?t=Uuzys2o8udzE7tgTwGIYqQ&s=09"
                  target="_blank"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4256_30020)">
                      <path
                        d="M14.2343 10.1625L22.9767 0H20.9048L13.3141 8.82375L7.25106 0H0.258057L9.42643 13.3433L0.258057 24H2.32993L10.3463 14.6818L16.7491 24H23.7421L14.2337 10.1625H14.2343ZM11.3967 13.4606L10.4676 12.132L3.07637 1.55962H6.25862L12.2232 10.092L13.1521 11.4206L20.9057 22.5112H17.7239L11.3967 13.4612V13.4606Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4256_30020">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>

                <Link
                  to="https://www.tiktok.com/@trendxapp?_r=1&_d=e92gi7h9d5kc00&sec_uid=MS4wLjABAAAARBG_SWPP8aNyfSucKNw2vxuTxOyRtjALXkJWK7zFgmht7aSITY7sDZlGTJ5JpV62&share_author_id=7350230991275148294&sharer_language=en&source=h5_m&u_code=dlf576aic94dg3&timestamp=1716195559&user_id=7027192211898532870&sec_user_id=MS4wLjABAAAAW-72IxWH1Bn8epS-3pn-lf1tMYiR1bb6PSE7MCDX3Y5f29S-8TC25JQEmWUauhcI&utm_source=copy&utm_campaign=client_share&utm_medium=android&share_iid=7358404207832090374&share_link_id=4e8a445d-a5c6-44cf-90f8-bd551429f42d&share_app_id=1233&ugbiz_name=ACCOUNT&ug_btm=b6880%2Cb2878&social_share_type=5&enable_checksum=1"
                  target="_blank"
                >
                  <svg
                    width="22"
                    height="24"
                    viewBox="0 0 22 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4256_30022)">
                      <path
                        d="M15.701 8.64215C17.2468 9.74656 19.1405 10.3964 21.1857 10.3964V6.46286C20.7986 6.46294 20.4126 6.42256 20.0339 6.34237V9.43861C17.9888 9.43861 16.0954 8.78888 14.5493 7.68455V15.7118C14.5493 19.7275 11.2922 22.9826 7.27481 22.9826C5.7758 22.9826 4.38248 22.5296 3.2251 21.7528C4.54609 23.1028 6.3883 23.9402 8.42631 23.9402C12.4441 23.9402 15.7012 20.6852 15.7012 16.6693V8.64215H15.701ZM17.122 4.6737C16.332 3.81111 15.8133 2.69635 15.701 1.46391V0.958008H14.6095C14.8843 2.52438 15.8215 3.86259 17.122 4.6737ZM5.76612 18.6713C5.32476 18.093 5.08618 17.3853 5.08725 16.6578C5.08725 14.8212 6.57699 13.3321 8.41497 13.3321C8.75745 13.3319 9.0979 13.3845 9.42438 13.4879V9.46642C9.04286 9.4142 8.65787 9.39194 8.27304 9.40013V12.5302C7.94642 12.4267 7.60578 12.3742 7.26314 12.3745C5.42524 12.3745 3.93558 13.8635 3.93558 15.7003C3.93558 16.9991 4.68016 18.1236 5.76612 18.6713Z"
                        fill="#FF004F"
                      />
                      <path
                        d="M14.5492 7.68446C16.0954 8.78879 17.9886 9.43853 20.0338 9.43853V6.34228C18.8922 6.09922 17.8816 5.50303 17.1218 4.6737C15.8213 3.8625 14.8842 2.5243 14.6094 0.958008H11.7424V16.6692C11.7359 18.5008 10.2487 19.9838 8.41471 19.9838C7.33405 19.9838 6.37389 19.469 5.76586 18.6713C4.68006 18.1236 3.9354 16.999 3.9354 15.7004C3.9354 13.8637 5.42506 12.3746 7.26296 12.3746C7.6151 12.3746 7.95449 12.4293 8.27286 12.5303V9.40022C4.32594 9.48173 1.15173 12.705 1.15173 16.6692C1.15173 18.6482 1.94216 20.4421 3.22509 21.7529C4.38246 22.5296 5.77571 22.9827 7.2748 22.9827C11.2923 22.9827 14.5493 19.7274 14.5493 15.7118L14.5492 7.68446Z"
                        fill="black"
                      />
                      <path
                        d="M20.0338 6.34179V5.50477C19.0044 5.50633 17.9953 5.21819 17.1218 4.6733C17.895 5.51927 18.913 6.10265 20.0338 6.34196M14.6094 0.957517C14.5832 0.807857 14.5631 0.657195 14.5491 0.505903V0H10.5905V15.7113C10.5842 17.5428 9.09699 19.0258 7.2629 19.0258C6.74295 19.0266 6.2301 18.905 5.76579 18.671C6.37382 19.4686 7.33399 19.9833 8.41465 19.9833C10.2486 19.9833 11.7359 18.5004 11.7424 16.6688V0.9576L14.6094 0.957517ZM8.27305 9.39972V8.5085C7.94226 8.46328 7.60877 8.44066 7.2749 8.4408C3.25697 8.4408 0 11.696 0 15.7113C0 18.2287 1.28003 20.4473 3.22519 21.7524C1.94226 20.4416 1.15183 18.6476 1.15183 16.6687C1.15183 12.7046 4.32596 9.48124 8.27305 9.39972Z"
                        fill="#00F2EA"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4256_30022">
                        <rect width="21.1862" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              </div>
            </div>

            <div>
              <p className="card-head">Contact us</p>
              <Link
                to={"https://wa.me/+16694994294"}
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p className="card-p card-p-mg-top2">+1 669 499 4294</p>
              </Link>
              <a
                href="mailto:tokenize@trendx.app"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p className="card-p card-p-mg-top3">Tokenize@trendx.app</p>
              </a>
            </div>

            <div className="ft-content">
              <div className="card3">
                <div className="card-head">Company</div>
                <Link to={"/about"} style={{ textDecoration: "none" }}>
                  <p className="card-p card-p-mg-top2">About us</p>
                </Link>
                {/* <Link to={"/blog"} style={{ textDecoration: "none" }}>
                  <p className="card-p card-p-mg-top2">Blog</p>
                </Link> */}
                <Link
                  to={""}
                  style={{ textDecoration: "none" }}
                  onClick={onFactoryProject}
                >
                  <p className="card-p card-p-mg-top2">The factory</p>
                </Link>
                <Link to={""} style={{ textDecoration: "none" }} onClick={faq}>
                  <p className="card-p card-p-mg-top2">FAQs</p>
                </Link>
              </div>

              {/* <div className="card3 card4">
                <div className="card-head">Products</div>
                <Link
                  to={""}
                  style={{ textDecoration: "none" }}
                  onClick={onTxApp}
                >
                  <p className="card-p card-p-mg-top2">Trendx app</p>
                </Link>
                <Link
                  to={""}
                  style={{ textDecoration: "none" }}
                  onClick={onReserveCard}
                >
                  <p className="card-p card-p-mg-top2">Reserve card</p>
                </Link>
              </div> */}

              {/* card5 */}
              <div className="card3">
                <div className="card-head">Services</div>
                <Link
                  to={""}
                  style={{ textDecoration: "none" }}
                  onClick={onDefi}
                >
                  <p className="card-p card-p-mg-top2">Research</p>
                </Link>
                <Link
                  to={""}
                  style={{ textDecoration: "none" }}
                  onClick={onDefi}
                >
                  <p className="card-p card-p-mg-top2">Advisory</p>
                </Link>
                <Link
                  to={""}
                  style={{ textDecoration: "none" }}
                  onClick={onTkProjects}
                >
                  <p className="card-p card-p-mg-top2">Tokenization</p>
                </Link>
              </div>
            </div>

            {/* <p className="card-p card-p-mg-top1">Download the Trendx app</p> */}
            {/* <div className="fbtn-cont">
              <div className="app-btn">
                <AppStoreBtn />
              </div>
              <PlayStoreBtn />
            </div> */}
          </div>
          <div className="bottom-footer">
            <div className="ptl">
              <Link to="/ppScreen" className="link">
                <p className="pp-text">Privacy Policy</p>
              </Link>
              <Link to="/termsofuse" className="link">
                <p className="pp-text">Terms of Services</p>
              </Link>
              <Link to="/legalScreen" className="link">
                <p className="pp-text">AML Policy</p>
              </Link>
            </div>
            <p className="copyright">© 2024 All Rights Reserved</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="footer-cont">
            <div className="card card1">
              <Link className="footer-logo" to="/">
                <img src={Logo} />
              </Link>
              {/* <p className="card-p card-p-mg-top1">Download the Trendx app</p> */}
              {/* <div className="fbtn-cont">
                <AppStoreBtn />
                <PlayStoreBtn />
              </div> */}
            </div>

            <div className="card card2">
              <p className="card-head">Contact us</p>
              <Link
                to={"https://wa.me/+16694994294"}
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p className="card-p card-p-mg-top2">+1 669 499 4294</p>
              </Link>
              <a
                href="mailto:tokenize@trendx.app"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <p className="card-p card-p-mg-top3">Tokenize@trendx.app</p>
              </a>

              <div className="socials">
                <p className="s-card-head">Follow us</p>
                <div className="s-icons">
                  <Link
                    to="https://www.linkedin.com/company/trendx/mycompany/"
                    target="_blank"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4256_30010)">
                        <path
                          d="M18.375 0H5.625C2.5184 0 0 2.5184 0 5.625V18.375C0 21.4816 2.5184 24 5.625 24H18.375C21.4816 24 24 21.4816 24 18.375V5.625C24 2.5184 21.4816 0 18.375 0Z"
                          fill="white"
                        />
                        <path
                          d="M18.375 0H5.625C2.5184 0 0 2.5184 0 5.625V18.375C0 21.4816 2.5184 24 5.625 24H18.375C21.4816 24 24 21.4816 24 18.375V5.625C24 2.5184 21.4816 0 18.375 0Z"
                          fill="#0A66C2"
                        />
                        <path
                          d="M17.317 20.408H20.0611C20.1605 20.408 20.2559 20.3685 20.3262 20.2982C20.3965 20.2279 20.4361 20.1325 20.4361 20.0331L20.4375 14.2354C20.4375 11.2051 19.7845 8.87588 16.2433 8.87588C14.8972 8.82581 13.6277 9.51975 12.9427 10.6785C12.9393 10.6841 12.9342 10.6885 12.9281 10.691C12.9221 10.6934 12.9154 10.6938 12.909 10.6921C12.9027 10.6904 12.8971 10.6867 12.8931 10.6815C12.8891 10.6763 12.8869 10.67 12.8869 10.6634V9.53063C12.8869 9.43117 12.8474 9.33579 12.777 9.26546C12.7067 9.19513 12.6113 9.15563 12.5119 9.15563H9.90778C9.80832 9.15563 9.71294 9.19513 9.64262 9.26546C9.57229 9.33579 9.53278 9.43117 9.53278 9.53063V20.0325C9.53278 20.132 9.57229 20.2273 9.64262 20.2977C9.71294 20.368 9.80832 20.4075 9.90778 20.4075H12.6517C12.7511 20.4075 12.8465 20.368 12.9168 20.2977C12.9871 20.2273 13.0267 20.132 13.0267 20.0325V14.8413C13.0267 13.3734 13.3051 11.9519 15.1249 11.9519C16.9188 11.9519 16.942 13.6315 16.942 14.9363V20.033C16.942 20.1324 16.9815 20.2278 17.0519 20.2981C17.1222 20.3685 17.2176 20.408 17.317 20.408ZM3.5625 5.59012C3.5625 6.70237 4.47816 7.61756 5.5905 7.61756C6.70256 7.61747 7.61766 6.70172 7.61766 5.58966C7.61747 4.47759 6.70228 3.5625 5.59012 3.5625C4.47769 3.5625 3.5625 4.47787 3.5625 5.59012ZM4.21491 20.408H6.96244C7.06189 20.408 7.15728 20.3685 7.2276 20.2981C7.29793 20.2278 7.33744 20.1324 7.33744 20.033V9.53063C7.33744 9.43117 7.29793 9.33579 7.2276 9.26546C7.15728 9.19513 7.06189 9.15563 6.96244 9.15563H4.21491C4.11545 9.15563 4.02007 9.19513 3.94974 9.26546C3.87942 9.33579 3.83991 9.43117 3.83991 9.53063V20.033C3.83991 20.1324 3.87942 20.2278 3.94974 20.2981C4.02007 20.3685 4.11545 20.408 4.21491 20.408Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4256_30010">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>

                  <Link
                    to="https://www.instagram.com/trendx.space/"
                    target="_blank"
                  >
                    <img src={ig} alt="" />
                  </Link>

                  <Link
                    to="https://twitter.com/trendxapp?t=Uuzys2o8udzE7tgTwGIYqQ&s=09"
                    target="_blank"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4256_30020)">
                        <path
                          d="M14.2343 10.1625L22.9767 0H20.9048L13.3141 8.82375L7.25106 0H0.258057L9.42643 13.3433L0.258057 24H2.32993L10.3463 14.6818L16.7491 24H23.7421L14.2337 10.1625H14.2343ZM11.3967 13.4606L10.4676 12.132L3.07637 1.55962H6.25862L12.2232 10.092L13.1521 11.4206L20.9057 22.5112H17.7239L11.3967 13.4612V13.4606Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4256_30020">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>

                  <Link
                    to="https://www.tiktok.com/@trendxapp?_r=1&_d=e92gi7h9d5kc00&sec_uid=MS4wLjABAAAARBG_SWPP8aNyfSucKNw2vxuTxOyRtjALXkJWK7zFgmht7aSITY7sDZlGTJ5JpV62&share_author_id=7350230991275148294&sharer_language=en&source=h5_m&u_code=dlf576aic94dg3&timestamp=1716195559&user_id=7027192211898532870&sec_user_id=MS4wLjABAAAAW-72IxWH1Bn8epS-3pn-lf1tMYiR1bb6PSE7MCDX3Y5f29S-8TC25JQEmWUauhcI&utm_source=copy&utm_campaign=client_share&utm_medium=android&share_iid=7358404207832090374&share_link_id=4e8a445d-a5c6-44cf-90f8-bd551429f42d&share_app_id=1233&ugbiz_name=ACCOUNT&ug_btm=b6880%2Cb2878&social_share_type=5&enable_checksum=1"
                    target="_blank"
                  >
                    <svg
                      width="22"
                      height="24"
                      viewBox="0 0 22 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4256_30022)">
                        <path
                          d="M15.701 8.64215C17.2468 9.74656 19.1405 10.3964 21.1857 10.3964V6.46286C20.7986 6.46294 20.4126 6.42256 20.0339 6.34237V9.43861C17.9888 9.43861 16.0954 8.78888 14.5493 7.68455V15.7118C14.5493 19.7275 11.2922 22.9826 7.27481 22.9826C5.7758 22.9826 4.38248 22.5296 3.2251 21.7528C4.54609 23.1028 6.3883 23.9402 8.42631 23.9402C12.4441 23.9402 15.7012 20.6852 15.7012 16.6693V8.64215H15.701ZM17.122 4.6737C16.332 3.81111 15.8133 2.69635 15.701 1.46391V0.958008H14.6095C14.8843 2.52438 15.8215 3.86259 17.122 4.6737ZM5.76612 18.6713C5.32476 18.093 5.08618 17.3853 5.08725 16.6578C5.08725 14.8212 6.57699 13.3321 8.41497 13.3321C8.75745 13.3319 9.0979 13.3845 9.42438 13.4879V9.46642C9.04286 9.4142 8.65787 9.39194 8.27304 9.40013V12.5302C7.94642 12.4267 7.60578 12.3742 7.26314 12.3745C5.42524 12.3745 3.93558 13.8635 3.93558 15.7003C3.93558 16.9991 4.68016 18.1236 5.76612 18.6713Z"
                          fill="#FF004F"
                        />
                        <path
                          d="M14.5492 7.68446C16.0954 8.78879 17.9886 9.43853 20.0338 9.43853V6.34228C18.8922 6.09922 17.8816 5.50303 17.1218 4.6737C15.8213 3.8625 14.8842 2.5243 14.6094 0.958008H11.7424V16.6692C11.7359 18.5008 10.2487 19.9838 8.41471 19.9838C7.33405 19.9838 6.37389 19.469 5.76586 18.6713C4.68006 18.1236 3.9354 16.999 3.9354 15.7004C3.9354 13.8637 5.42506 12.3746 7.26296 12.3746C7.6151 12.3746 7.95449 12.4293 8.27286 12.5303V9.40022C4.32594 9.48173 1.15173 12.705 1.15173 16.6692C1.15173 18.6482 1.94216 20.4421 3.22509 21.7529C4.38246 22.5296 5.77571 22.9827 7.2748 22.9827C11.2923 22.9827 14.5493 19.7274 14.5493 15.7118L14.5492 7.68446Z"
                          fill="black"
                        />
                        <path
                          d="M20.0338 6.34179V5.50477C19.0044 5.50633 17.9953 5.21819 17.1218 4.6733C17.895 5.51927 18.913 6.10265 20.0338 6.34196M14.6094 0.957517C14.5832 0.807857 14.5631 0.657195 14.5491 0.505903V0H10.5905V15.7113C10.5842 17.5428 9.09699 19.0258 7.2629 19.0258C6.74295 19.0266 6.2301 18.905 5.76579 18.671C6.37382 19.4686 7.33399 19.9833 8.41465 19.9833C10.2486 19.9833 11.7359 18.5004 11.7424 16.6688V0.9576L14.6094 0.957517ZM8.27305 9.39972V8.5085C7.94226 8.46328 7.60877 8.44066 7.2749 8.4408C3.25697 8.4408 0 11.696 0 15.7113C0 18.2287 1.28003 20.4473 3.22519 21.7524C1.94226 20.4416 1.15183 18.6476 1.15183 16.6687C1.15183 12.7046 4.32596 9.48124 8.27305 9.39972Z"
                          fill="#00F2EA"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4256_30022">
                          <rect width="21.1862" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>

            {/* card3 */}
            <div className="card3">
              <div className="card-head">Company</div>
              <Link to={"/about"} style={{ textDecoration: "none" }}>
                <p className="card-p card-p-mg-top2">About us</p>
              </Link>
              {/* <Link to={"/blog"} style={{ textDecoration: "none" }}>
                <p className="card-p card-p-mg-top2">Blog</p>
              </Link> */}
              <Link
                to={""}
                style={{ textDecoration: "none" }}
                onClick={onFactoryProject}
              >
                <p className="card-p card-p-mg-top2">The Factory</p>
              </Link>
              <Link to={""} style={{ textDecoration: "none" }} onClick={faq}>
                <p className="card-p card-p-mg-top2">FAQs</p>
              </Link>
            </div>
            {/* card4 */}
            {/* <div className="card3 card4">
              <div className="card-head">Products</div>
              <Link
                to={""}
                style={{ textDecoration: "none" }}
                onClick={onTxApp}
              >
                <p className="card-p card-p-mg-top2">Trendx app</p>
              </Link>
              <Link
                to={""}
                style={{ textDecoration: "none" }}
                onClick={onReserveCard}
              >
                <p className="card-p card-p-mg-top2">Reserve card</p>
              </Link>
            </div> */}

            {/* card5 */}
            <div className="card3 card5">
              <div className="card-head">Services</div>
              <Link to={""} style={{ textDecoration: "none" }} onClick={onDefi}>
                <p className="card-p card-p-mg-top2">Research</p>
              </Link>
              <Link to={""} style={{ textDecoration: "none" }} onClick={onDefi}>
                <p className="card-p card-p-mg-top2">Advisory</p>
              </Link>
              <Link
                to={""}
                style={{ textDecoration: "none" }}
                onClick={onTkProjects}
              >
                <p className="card-p card-p-mg-top2">Tokenization</p>
              </Link>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="ptl">
              <Link to="/ppScreen" className="link">
                <p className="pp-text">Privacy Policy</p>
              </Link>
              <Link to="/termsofuse" className="link">
                <p className="pp-text">Terms of Service</p>
              </Link>
              <Link to="/legalScreen" className="link">
                <p className="pp-text">AML Policy</p>
              </Link>
            </div>
            <p className="copyright">© 2024 All Rights Reserved</p>
          </div>
        </div>
      )}
    </div>
  );
}
